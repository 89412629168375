.draggable {
  position: absolute;
  pointer-events: none;
  height: 100%;
  width: 100%;
}

.hide-scrollbar-chrome::-webkit-scrollbar {
  display: none;
}

.fadeInFlex {
  display: flex;
}

@media print {
  .page {
    page-break-inside: avoid;
  }
}

@page {
  size: auto;
  margin-top: 20mm;
  margin-bottom: 20mm;
}
